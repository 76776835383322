/* WavingHandStyle.css */
@keyframes wave {
    0%, 100% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-30deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(30deg);
    }
  }
  
  .waving-hand {
    font-size: 2em; /* Adjust the font size as needed */
    animation: wave 1s infinite;
  }